
<!--
Para aplicar------------------------------------------
schema servidor:  meter en obs_incidencias.comp.data:{texto:'', select1:0, select2:0}
app.js añadir modal70
ctrlobs3
uiobs3Motivos
uiobs3Texto
mixinMto: añadir funcion after_guardar_Api ()
exp_M:añadir funcion after_guardar_Api () crea aviso cuando interviene el ctd (select1==2) 
mixinCtrls: reescrito obs3 -->
<template>
  <div>
    <div class="columna">
      <div style="display: flex">
        <v-icon @click="lanzar()">{{ icono }}</v-icon>
      </div>

      <div style="display: flex">
        <v-textarea
          :label="schemaComponente[1]"
          v-model="mensajesView"
          v-bind="$textarea"         
          :rows="rows"
          readonly
          no-resize
          @click:prepend-inner="dialog = true">                  
        </v-textarea>
      </div>
    </div>

    <!-- componente segun tipo de observacion -->
    <component
      :is="dynamicC"
      @update="update"
      :open="open"
      :edicion="edicion"
      :_dataC="dataC"
      :schema="schema"
      :schemaComponente="schemaComponente"
      :arrMensajes="arrMensajes"
      :rolesMensajes="rolesMensajes"
      :rolesTitulo="rolesTitulo"
      :motivos="motivos"
      :agrupar="agrupar"
    >
    </component>
  </div>
</template>


<script>

  import uiobs3Motivos from "@/components/uiobs3Motivos.vue";
  import uiobs3Texto from "@/components/uiobs3Texto.vue";
 
  // let dataCbis={encabeza:'bis',select1:0, select2:0};
  export default {
    data() {
      return {
        // datos de  componente
        open: false,
        // componente dinamico
        dynamicC: "",
        // almaceno SchemaComponente.comp.data
        dataC: {},
        // almacena fusion de mensajes de todos los fields del record implicado
        arrMensajes: [],
        // Convierte la array de  mensajes en una cadena y formatea para mostrar
        strMensajes: "",
        // Objeto donde se agrupan los mensajes por rol
        rolesMensajes: { gestion: [], apds: [], jz: [] },
        // Títulos de los roles para mostrar
        rolesTitulo: { gestion: "CTD", apds: "APD", jz: "Coordinador de Servicio" },
      
      };
    },
    components: { uiobs3Motivos, uiobs3Texto },
    props: {
      record: { type: [Object, Array] },
      schema: { type: [Object, Array], required: true },
      schemaComponente: { type: Array, default: ()=> [] },      
      edicion: { type: Boolean, default: false },
      titulo: { type: String, default: "Observaciones" },
      motivos: { type: Boolean, default: false },
      agrupar: { type: Boolean, default: false },
      icono: { type: String, default: "mdi-comment-eye-outline" },
      rows:  { type: String, default: "4" },
    },
    // ------------------------
    watch: {
      schemaComponente: {
        immediate: true,
        deep: true,
        handler() {
          console.log(this.dataC, this.schemaComponente);

          if (this.schemaComponente[4].comp.data) {
            this.dataC = JSON.parse(
              JSON.stringify(this.schemaComponente[4].comp.data)
            );
          }

          this.ObservacionesGet();
        },
      },
    },

    computed: {
      mensajesView() {
        let cadenas = "";

        // Observaciones con motivos
        if (this.schemaComponente[4].comp.data && this.motivos) {
          if (!this.dataC.texto) return this.strMensajes;
          cadenas = this.dataC.encabeza == "" ? "" : this.dataC.encabeza + "\n";
          cadenas += this.dataC.texto + "\n" + this.strMensajes;
          return cadenas;
        }

        // observaciones simple
        cadenas = this.strMensajes;        
        if (this.schemaComponente[4].comp.valueAux != "")
          cadenas = this.schemaComponente[4].comp.valueAux + "\n" + cadenas;
        return cadenas;
      },
    },
    
    methods: {
      lanzar() {
        this.open = true;
        //lanzo ob3Motivos
        if (this.schemaComponente[4].comp.data && this.motivos) {
          this.dynamicC = "uiobs3Motivos";
          this.dataC = JSON.parse(
            JSON.stringify(this.schemaComponente[4].comp.data)
          );
          return;
        }
        // lanzo ctrlobs3texto
        this.dynamicC = "uiobs3Texto";
      },

      update(ev) {
        this.open = false;
        if (!this.edicion) return;
        
        // obs3Texto
        if (this.dynamicC == "uiobs3Texto") {
          this.$set(this.schemaComponente[4].comp, 'valueAux', ev.data.valueAux);

          if (this.schemaComponente[4].comp.data) {
            this.$set(this.schemaComponente[4].comp.data, 'texto', ev.data.valueAux);
          }
          return;
        }

        //obs3Motivos
        this.dataC = JSON.parse(JSON.stringify(ev.data));
        this.$set(this.schemaComponente[4].comp, 'data', ev.data);
        this.dynamicC = "";
        //this.$emit("update", { component: "obs3New", data: this.dataC });

        //
      },
      ObservacionesGet() {
        // console.log('ctrlObs3 observacionesGet  @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ ', this.schemaComponente.label,this.schemaComponente);

        // rolesMensajes={gestion:[], apds:[], jz:[]}
        // rolesTitulo={gestion:'CTD', apds:'APD', jz:'Jefe de Zona'}
        this.arrMensajes = [];
        this.rolesMensajes = { gestion: [], apds: [], jz: [] };
        let fieldAnterior = "";
        //
        // Recopilo datos por cada field incluido - ej: schemaComponente.comp.fields:{apd:obs_apd, gestion:obs_ctd,jz:obs_jz }
        for (const rol in this.schemaComponente[4].comp.fields) {
          // ej. primer paso rol=apd, rolfield=obs_apd
          let rolField = this.schemaComponente[4].comp.fields[rol];
          console.log('ROL: ', rolField, fieldAnterior);
          // por compatibilidad con esquema antiguo compruebo fieldAnteior ya que podria repetirse
          if (fieldAnterior == rolField) continue;
          fieldAnterior = rolField;
          if (!this.record[rolField]) continue;
          if (this.record[rolField] == "") continue;
          //

          // obtengo array de rolField con datos decodificados
          let arrDecode = this.decodeField(this.record[rolField], rol);
          if (arrDecode != null) {
            this.rolesMensajes[rol] = [...arrDecode];
            this.arrMensajes = [...this.arrMensajes, ...arrDecode];
          }

        }

        //ordeno arrMensajes cronológicamente
        this.arrMensajes = this.arrMensajes.sort((x, y) => y.orden - x.orden);

        // formateo string para textarea
        this.strMensajes = "";
        this.arrMensajes.forEach((item) => {
          this.strMensajes +=
            "" + item.dia + " " + item.hora + " " + item.name + " " + item.texto;
        });
        return;
      },
      decodeField(datos, rol) {
        try {
          let arrDecode = [];

          // decodifico datos y formateo
          let ar = datos.split("[");
          if (ar) ar.shift();
          if (!ar) return [];

          ar.forEach((item) => {
            //
            let A_idTexto = item.split("]");
            let texto = A_idTexto[1];
            let dia, hora, name, pos;

            // compruebo si la identificación es correcta
            // formato. usu hora fecha
            if (A_idTexto[0].indexOf(":") < 0 || A_idTexto[0].indexOf("-") < 0) {
              A_idTexto[0] = "(check) 00:00 00-00-0000";
              // 20-01-2021 06:24
            }

            // CONVERSION DE FORMATO ANTIGUO
            // fecha erronea con formato [20-01-2021 06:24] (longitud 16)
            // formato correcto: [CSABIOTE 18:47 28-01-2021]
            if (A_idTexto[0].length === 16) {
              let fecha = (A_idTexto[0] =
                A_idTexto[0].slice(11, 16) + " " + A_idTexto[0].slice(0, 10));
              let usu = A_idTexto[1].trimStart();
              usu = usu.slice(0, usu.indexOf(" "));
              A_idTexto[0] = usu + " " + fecha;
              texto = texto.trimStart();
              texto = texto.slice(texto.indexOf(" ") + 1);
            }
            //---------------------------------------------------------
    
            // get dia
            pos = A_idTexto[0].lastIndexOf(" ");
            dia = A_idTexto[0].slice(pos + 1);

            // elimino dia y me quedo con name y hora
            A_idTexto[0] = A_idTexto[0].slice(0, pos);

            // saco hora

            pos = A_idTexto[0].lastIndexOf(" ");
            hora = A_idTexto[0].slice(pos + 1);
            let A_hora = hora.split(":");
            hora = A_hora[0]
              .padStart(2, "0")
              .concat(":", A_hora[1].padStart(2, "0"));

            // elimino hora y me queda name
            A_idTexto[0] = A_idTexto[0].slice(0, pos);
            name = A_idTexto[0].slice(0, pos);
            // en A_idTexto[0] me queda el nombre
            let A_dia = dia.split("-");
            let fechaDate = new Date(
              A_dia[2],
              A_dia[1] - 1,
              A_dia[0],
              A_hora[0],
              A_hora[1],
              0,
              0,
              0
            );
            arrDecode.push({
              dia: dia,
              hora: hora,
              name: name,
              texto: texto,
              color: rol,
              // para ordenar ascendente o descendente añado orden
              orden: fechaDate.getTime(),
            });
          });
          return arrDecode;

        } catch {
          return null;
        }
      },
    },
  };

</script>